import React, {useEffect} from "react";
// import $ from 'jquery';
// import {correctHeight, detectBody} from '../../helpers/themeHelper';

// import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header";
import Footer from "../Footer";

const Layout = (props) => {
    const {children} = props;

    useEffect(() => {
        // Run correctHeight function on load and resize window event
        // $(window).bind("load resize", function() {
        //     correctHeight();
        //     detectBody();
        // });

        // // Correct height of wrapper after metisMenu animation.
        // $('.metismenu a').click(() => {
        //     setTimeout(() => {
        //         correctHeight();
        //     }, 300)
        // });
    }, []);    

    return (<>
            <Header />
            {children}
            <Footer />
            </>
        );
}

export default Layout;
