export const searchResults = [
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/2dbc5c12-564b-474d-844f-38edcf4f9971/07806090.jpg?sw=80",
        "description": "Harbor Breeze Armitage Builder Series 52-in White LED Indoor Flush Mount Ceiling Fan with Light (5-Blade)",
        "navigationUrl": "https://www.lowes.com/pd/Harbor-Breeze-Armitage-Builder-Series-52-in-White-LED-Indoor-Flush-Mount-Ceiling-Fan-with-Light-Kit-5-Blade/1000218421?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$599.99",
        "availability": 14,
        "rating": 3,
        "aisle": 6,
        "bay": 3
    },
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/b171a565-04f3-4a3a-be9d-3e45c930329e/03137430.jpg?sw=80",
        "description": "Hunter Studio Series 52-in New Bronze LED Indoor Downrod or Flush Mount Ceiling Fan with Light (5-Blade)",
        "navigationUrl": "https://www.lowes.com/pd/Hunter-Studio-Series-52-in-Satin-Bronze-Indoor-Ceiling-Fan-with-Light-Kit-5-Blade/50057409?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$619.99",
        "availability": 34,
        "rating": 4,
        "aisle": 10,
        "bay": 1
    },
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/41514186-091d-43a8-b4fc-8648efc42b7e/03137426.jpg?sw=80",
        "description": "Hunter Studio Series 52-in Antique Brass LED Indoor Downrod or Flush Mount Ceiling Fan with Light (5-Blade)",
        "navigationUrl": "https://www.lowes.com/pd/Hunter-Studio-Series-LED-52-in-Antique-Brass-LED-Indoor-Ceiling-Fan-with-Light-Kit-5-Blade/50057419?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$809.99",
        "availability": 0,
        "rating": 3,
        "aisle": 9,
        "bay": 3
    },
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/ac07a66b-85ac-4f83-8a52-fefb5c5e7703/40462098.jpg?sw=80",
        "description": "kathy ireland HOME by Luminance Pro Series 50-in Antique Brass LED Indoor Downrod or Flush Mount Ceiling Fan with Light (5-Blade)",
        "navigationUrl": "https://www.lowes.com/pd/Emerson-Emerson-50-in-Pro-Series-Ceiling-Fan-in-Antique-Brass-Finish/5000928001?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$319.99",
        "availability": 21,
        "rating": 5,
        "aisle": 19,
        "bay": 1
    },
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/04e18bf4-0595-43e5-b4f6-f9b5404ad25f/16800441.jpg?sw=80",
        "description": "Wintergreen Lighting 20-in Hanging Light Display with Red LED Lights",
        "navigationUrl": "https://www.lowes.com/pd/Wintergreen-Lighting-20-in-Red-Commercial-Light-Ball/1003236002?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$989.99",
        "availability": 28,
        "rating": 3,
        "aisle": 4,
        "bay": 1
    },
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/b3ef5061-37b3-4f80-9b3c-b9541fadf020/09830240.jpg?sw=80",
        "description": "Harbor Breeze Armitage 52-in Bronze LED Indoor Flush Mount Ceiling Fan with Light (5-Blade)",
        "navigationUrl": "https://www.lowes.com/pd/Harbor-Breeze-Armitage-52-in-Bronze-LED-Indoor-Flush-Mount-Ceiling-Fan-with-Light-Kit-5-Blade/1000600557?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$429.99",
        "availability": 14,
        "rating": 3,
        "aisle": 20,
        "bay": 2
    },
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/e832163b-6be3-4167-b951-7afba21a16a2/14073876.jpg?sw=80",
        "description": "Hunter Hartland 52-in Noble Bronze LED Indoor Downrod or Flush Mount Ceiling Fan with Light (5-Blade)",
        "navigationUrl": "https://www.lowes.com/pd/Hunter-Hartland-LED-52-in-Satin-Bronze-LED-Indoor-Ceiling-Fan-with-Light-Kit-5-Blade/1001816240?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$1959.99",
        "availability": 39,
        "rating": 0,
        "aisle": 7,
        "bay": 5
    },
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/0bdbf0ea-ba6a-472b-848d-6205c0835dec/07927632.png?sw=80",
        "description": "Harbor Breeze Armitage 42-in White LED Indoor Flush Mount Ceiling Fan with Light (4-Blade)",
        "navigationUrl": "https://www.lowes.com/pd/Harbor-Breeze-Armitage-42-in-White-LED-Indoor-Flush-Mount-Ceiling-Fan-with-Light-Kit-4-Blade/1000195087?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$409.99",
        "availability": 20,
        "rating": 4,
        "aisle": 13,
        "bay": 1
    },
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/2f76e2c6-b9ee-4ea4-94b8-8f40ca0548e6/04933940.jpg?sw=80",
        "description": "Fanimation Studio Collection Prop 60-in Brushed Nickel Indoor Ceiling Fan with Remote (3-Blade)",
        "navigationUrl": "https://www.lowes.com/pd/Fanimation-Studio-Collection-Prop-60-in-Brushed-Nickel-Indoor-Ceiling-Fan-and-Remote-3-Blade/1000002332?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$1129.99",
        "availability": 2,
        "rating": 4,
        "aisle": 14,
        "bay": 4
    },
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/e6ea70c9-ea13-4a37-8eac-45c5abba0459/09477008.jpg?sw=80",
        "description": "Hunter Creekside 52-in Brushed Nickel LED Indoor Downrod or Flush Mount Ceiling Fan with Light (5-Blade)",
        "navigationUrl": "https://www.lowes.com/pd/Hunter-Creekside-LED-52-in-Satin-Nickel-LED-Indoor-Ceiling-Fan-with-Light-Kit-5-Blade/1000522557?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$1049.99",
        "availability": 0,
        "rating": 3,
        "aisle": 1,
        "bay": 2
    },
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/4d371b8a-478a-4a08-9a3f-6c9b35523ac4/16863159.jpg?sw=80",
        "description": "Kichler Barrington 52-in Distressed Black and Wood Tone LED Indoor Ceiling Fan with Light Remote (5-Blade)",
        "navigationUrl": "https://www.lowes.com/pd/Kichler-Barrington-52-in-Distressed-Black-LED-Indoor-Ceiling-Fan-with-Light-Kit-and-Remote-5-Blade/1000678797?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$589.99",
        "availability": 24,
        "rating": 5,
        "aisle": 2,
        "bay": 4
    },
    {
        "imageUrl": "https://mobileimages.lowes.com/productimages/4cdd174e-1589-4db8-9c5b-f539a0c31ddc/09230371.jpg?sw=80",
        "description": "Harbor Breeze Waveport 52-in White LED Indoor/Outdoor Downrod or Flush Mount Ceiling Fan with Light (5-Blade)",
        "navigationUrl": "https://www.lowes.com/pd/Harbor-Breeze-Waveport-52-in-White-LED-Indoor-Outdoor-Ceiling-Fan-with-Light-Kit-5-Blade/1000604217?utm_source=vui&utm_medium=other&utm_campaign=customer_service",
        "price": "$1689.99",
        "availability": 22,
        "rating": 3,
        "aisle": 7,
        "bay": 3
    }
]
