const es = {
    heroTitle: 'Vax Pass',
    heroHeadline: 'Comparte tu estado actual de salud para que puedas volver a tu vida y a tus viajes. Consigue tu pase',
    heroHeadlineLink: 'aquí',
    heroComingToAppstore: 'La aplicación de escaneo estará disponible muy pronto en el App Store.',

    howItWorksTitle: 'Explicación',
    howItWorks1Line1: 'Prueba de laboratorio/vacunación',
    howItWorks1Line2: 'antes de la verificación',
    howItWorks2Line1: 'Verificación automática de prueba',
    howItWorks2Line2: 'de laboratorio/vacunación',
    howItWorks3Line1: 'Vax Pass is saved to',
    howItWorks3Line2: 'Apple Wallet',
    howItWorks3Line3: 'Google Pay, o',
    howItWorks3Line4: 'cómo un PDF',
    howItWorks4Line1: 'El pase es escaneado por negocios para',
    howItWorks4Line2: 'verificar la identidad de usuario y su estado de salud.',
    howItWorksBottom: 'Vax Pass sigue las pautas de la CDC para minimzar el riesgo de la tramisión de Covid-19. Vea el sitio sobre Covid-19 de la CDC para las últimas novedades.',

    visionTitle: 'Nuestra Visión',
    vision1Para1: 'Estamos en el punto donde nuestro conocimiento del COVID-19 nos puede ayudar en nuestro camino hacia la normalidad. Creando políticas sensatas podemos reabrir y reactivar la economía reduciendo el riesgo a un nivel aceptable.  Si se hace con responsabilidad, comunidades enteras pronto estarán inmunizadas.',
    vision1Para2: 'Nuestra esperanza es que nuestro producto ayude a negocios de muchos rubros a reabrir de una manera rápida y segura.',
    vision2Para1: 'Los negocios que usen Vax Pass podrán reabrir con seguridad, comenzando la reactivación económica. Algunos de los negocios que Vax Pass planea ayudar son estadios, discotecas, bares, y aerolíneas.',
    vision2Para2: 'Mediante el proceso de vacunación acelere alrededor del mundo, Vax Pass ayudara a que la gente participe de en sus economías de una manera segura y con menos riesgo, dando paso a volver a la normalidad.',
    vision3Para1: 'Si esperamos parar la expansión del COVID y dejarlo en el pasado, necesitamos políticas inteligentes y sensatas para guiarnos ahí. Con Vax Pass avanzaremos, lento pero de una manera segura, hacia esa meta.',
    vision3Para2Start: 'Únete a esta meta',
    vision3Para2Link: 'consiguiendo tu Vax Pass ahora',
    vision3Para2End: ', que muy pronto podrá ser usado alrededor del mundo.',

    verification1Title: 'Verificación Digital',
    verification1Para1Start: 'Nuestra verificación digital genera un',
    verification1Para1Bold: 'Pase Verde',
    verification1Para1End: ', que por su misma naturaleza, no puede ser falsificado. Al verificar si los datos proporcionados existen en las bases de datos de instituciones médicas, la validad de Vax Pass es garantizada.',
    verification1Para2Start: 'Actualmente, nuestra verificación',
    verification1Para2Bold: 'soporta mas de 600 instituciones medicas',
    verification1Para2End: 'en Estados Unidos con mas de 100 millones de pacientes. Chequea si tu institución medica es soportada aquí.',
    verification1Para3Start: 'Contáctanos en support@vax-pass.org',
    verification1Para3End: 'para información sobre el soporte a otras instituciones médicas.',

    verification2Title: 'Image Verification',
    verification2Para1Start: 'Image verification generates a',
    verification2Para1Bold: 'Yellow Pass',
    verification2Para1End: ', allowing businesses to provide an additional layer of safety for their customers.',
    verification2Para2Start: 'This works through',
    verification2Para2Bold: 'state of the art machine learning techniques',
    verification2Para2End: 'that automatically verify medical record images.',
    verification2Para3: '10% of the records are manually screened for fraud with consequences for those who falsify records.',

    privacyTitle: 'Seguridad',
    privacy1Title: 'Vax Pass',
    privacy1Content: 'está diseñado para dar una experiencia buena al usuario mientras minimiza el riesgo de la transmisión del Covid:',
    privacy2Title: 'Pautas de la CDC:',
    privacy2Content: 'Está seguro de que estas siguiendo las pautas del CDC sabiendo cuando todos usan Vax Pass',
    privacy3Title: 'Tecnologias de la Nube:',
    privacy3Content: 'Construido sobre las ultimas tecnologías de la nube de Amazon, Vax Pass es rápido y seguro',
    privacy4Title: 'Presencia Mundial',
    privacy4Content: 'Pornto, Vax Pass será aceptado mundialmente',
    privacy5Title: 'Firmado digitalmente:',
    privacy5Content: 'Varias huellas digitales son revisados para garantizar la validez de cada pase',

    newsTitle: 'Noticias',
    news1Heading: 'Unión Europa propone usar un \'pase digital\' para COVID-19 vacunas/pruebas para...',
    news1Body: 'La Comisión Europea anunció que presenterá un plan legislativo a fines de este mes para lo que está llamando un “pase digital verde” — tambien conocido como un ceritficado digital — que dicen que será usado para facilitar los viajes internacionales en la edad del coronavirus.',
    news2Heading: 'Teatros de Broadway anuncian que reabriran como parte del NY PopsUp Festival',
    news2Body: 'Siguiendo el anuncio un festival de arte en la ciudad de Nueva York, Gobernador Cuomo anunció que conciertos en vivo podrán resumir a partir del 2 de abril. Varios “flex venues” estarán abriendo para ...',
    news3Heading: 'Los vacunados en Texas pronto pasaran a los infectados',
    news3Body1: 'Más de un año despues del primer caso confirmado del Covdi-19, la cantidad de Texanos vacunados pasará al número total de casos de coronavirus reportados en todo el estado.',
    news3Body2: 'Mas de 7% de Tejanos, o 2,177,635 personas, estan completamente...',
    news4Heading: 'Israel abre para los vacunados que llevan un \'Pase Verde\'  — casi la mitad...',
    news4Body: 'Benjamin Netanyahu de Israel disfrutaba de un cappuccino y torta en la terraza de un café de Jerusalén el Dómingo en la mañana para marcar el reabrimiento más grande de la economía de la nación desde hace un año.',
    news5Heading: 'British Airways planea un pase de viajes',
    news5Body: 'Bajo los planes, la gente que ha recibido ambas vacunas podrán registrar su estatus en la aplicación móvil de BA. El ejecutivo Sean Doyle alabó el "gran progreso" que el Reino Unido hizo en pelear contra la pandemia.',

    sendMessageTitle: 'Mándanos un Mensaje',
    sendMessageName: 'Su nombre',
    sendMessageEmail: 'Email',
    sendMessagePhone: 'Su número de celular',
    sendMessageContent: 'Escriba su mensaje...',
    sendMessageButton: 'ENVIAR MENSAJE',
    sendMessageSentButton: 'ENVIADO',

    headerHome: 'Inicio',
    headerHowItWorks: 'Explicación',
    headerStatus: 'Estatus',
    headerVision: 'Nuestra Visión',
    headerPrivacy: 'Seguridad',
    headerNews: 'Noticias',
    headerContact: 'Contacto',

    footerHowItWorks: 'Explicación',
    footerStatus: 'Estatus',
    footerVision: 'Nuestra Visión',
    footerPrivacy: 'Seguridad',
    footerNews: 'Noticias',
    footerContact: 'Contacto',
    'error.occured': 'Mil disculpas - Ocurrio un error',
    'error.homeButton': 'Ir al inicio',
    'verification.errorStart': 'Hubo un error verificando su',
    'verification.errorBold': 'COVID-19',
    'verification.errorEnd': 'registro',
    'verification.tryImage': 'TRATE DE USAR VERIFICACIÓN DE IMAGEN',
    'imageVerification.header': 'Pantalla de Verificación de Imagen',
    'imageVerification.firstName': 'Primer Nombre',
    'imageVerification.lastName': 'Apellido',
    'imageVerification.email': 'Correo Electrónico',
    'imageVerification.dob': 'Fecha de Nacimiento (MM/DD/YYYY)',
    'imageVerification.selectImage': 'Seleccionar Imagen',
    'imageVerification.imageSelected': 'Imagen Seleccionada',
    'imageVerification.imageDescription': 'Resultados de examen PCR o tarjeta de vacunación',
    'imageVerification.imageInvalid': 'Por favor seleccione una imagen valida',
    'imageVerification.submit': 'ENVIAR',
    'instantVerification.description': 'Vax Pass deja que los pacientes compartan parte de su historial médico incluyendo inmunizaciones, de manera temporal. ',
    'instantVerification.formHeader': 'INGRESA TU INFORMACÍON',
    'instantVerification.firstName': 'Primer Nombre',
    'instantVerification.lastName': 'Apellido',
    'instantVerification.dob': 'Fecha de Nacimiento (MM/DD/YYYY)',
    'instantVerification.email': 'Correo Electrónico',
    'instantVerification.username': 'Usuario',
    'instantVerification.password': 'Contraseña',
    'introScreen.description1Start': 'Los negocios usan',
    'introScreen.description1Heading': 'VAX PASS',
    'introScreen.description1End': 'para verificar si estas vacunado contra el COVID- 19 o si diste negativo en tu prueba de COVID.',
    'introScreen.description2Head': 'SEGURO',
    'introScreen.description2Body': 'La transferencia de tus datos es privada y segura. .',
    'introScreen.description3Head': 'PRIVADO',
    'introScreen.description3Body': 'Después de la verificación de tus datos, inhabilitamos nuestro acceso a tus datos médicos.',
    'introScreen.tosStart': 'Al tocar \'Continuar\' usted accede a nuestro',
    'introScreen.tosLink1': 'Términos de Servicio',
    'introScreen.tosMiddle': 'y',
    'introScreen.tosLink2': 'Política de Privacidad',
    'introScreen.next': 'CONTINUAR',
    'loading.averageWaitTime': 'Tiempo estimado de espera',
    'loading.seconds': 'segundos',
    'loading.uploadingTitle': 'Subiendo...',
    'loading.verifyingTitle': 'Verificando...',
    'loading.uploadingBody': 'Subiendo su imagen para verificación....',
    'loading.verifyingBody': 'Verificando su registro de vacunación...',
    'pdfPass.text1': 'Gracias por usar nuestro servicio.',
    'pdfPass.text2': 'Aquí esta su Vax Pass para acceder a cualquier zona Vax Pass. Su pase a sido aprobado y verificado. Si tiene cualquier duda o pregunta, por favor mande un mensaje a contact@vax-pass.org ',
    'pdfPass.text3': 'Visite vax-pass.org para ver como funciona Vax Pass',
    'pdfPass.text4': 'Para añadir este pase a una billetera electrónica, por favor abra el correo electrónico que le mandamos en un dispositivo Apple o Android.',
    'pdfPass.validityExpression': 'FECHA DEL EXAMEN',
    'pdfPass.invalid': 'FECHA DEL EXAMEN',
    'pdfPass.validAfter': 'VALIDO DESPUÉS',
    'pdfPass.validUntil': 'VALID HASTA',
    'pdfPass.name': 'NOMBRE',
    'pdfPass.dob': 'FECHA DE NACIMIENTO',
    'selectProvider.title': 'Seleccione su proveedor médico',
    'selectProvider.searchText': 'Buscar proveedor (e.j. Cone Health)',
    'selectProvider.cvs': 'CVS',
    'selectProvider.myChart': 'MyChart',
    'selectVerification.title': 'Seleccione el tipo de verificación',
    'selectVerification.digitalTitle1': 'Digital',
    'selectVerification.digitalTitle2': 'Verificación',
    'selectVerification.digitalBody1': 'mediante su proveedor de vacunación',
    'selectVerification.digitalBody2': '(Solamente vacunación)',
    'selectVerification.imageTitle1': 'Imagen',
    'selectVerification.imageTitle2': 'Verificación',
    'selectVerification.imageBody1': 'mediante imagen de vacunación',
    'selectVerification.imageBody2': 'o resutado de examen PCR',
    'submission.success': 'Felicidades, ¡sus datos fueron verificados!',
    'submission.continue': 'CONTINUAR',
    'verifiedRecord.invalidEmail': 'Por favor, ponga un correo electrónico válido',
    'verifiedRecord.title': 'Guarde su VaxPass',
    'verifiedRecord.email': 'Correo Electrónico',
    'verifiedRecord.send': 'ENVIAR A CORREO ELECTRÓNICO',
    'verifiedRecord.sent': 'CORREO ENVIADO',
    'verifiedRecord.saveApple': 'GUARDAR EN APPLE WALLET',
    'verifiedRecord.savePhone': 'GUARDAR EN TELÉFONO',
    'registration.notValidUntil': 'Nota: su Vax Pass no será válido hasta',
    'registration.validTill': 'Nota: su Vax Pass solo es válido hasta el',
    'registration.notVerified': 'Su vacunación no pudo ser verificada. Por favor intente usando la verificación de imagen en vez..',
    'registration.passNotValid': 'Su resultado PCR negativo fue confirmado, sin embargo, su Vax Pass no es válido. Para obtener un pase válido, por favor suba un resultado negativo de las ultimas 72 horas. ',
    'registration.errorUploading': 'Mil disculpas, hubo un error cargando la subiendo sus datos. Por favor, intente nuevamente actualizando esta página.',
    'registration.errorVerifying': 'Mil disculpas, hubo un error verificando su imagen',
    'registration.notFound': 'No pudimos encontrar la página que está buscando',
    'getInTouch': "Como Contactarnos"
};
export default es;
