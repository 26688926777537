import React from "react";
// reactstrap components
import { Container, Form } from "reactstrap";
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import instsantVerificationMainImage from "../assets/img/vaxpass/instantVerificationMainImage.png";
import "./stylesheets/InstantVerificationScreen.scss";
import { BlueButton } from "./Buttons";
import idIcon from "../assets/img/vaxpass/idIcon.svg";
import userIcon from "../assets/img/vaxpass/userIcon.svg";
import calendarIcon from "../assets/img/vaxpass/calendarIcon.svg";
import {DobInput, TextInput} from "./Inputs";
import { I18n } from 'aws-amplify';

class InstantVerificationScreen extends React.Component {
  state = {
    username: "",
    password: "",
  };

  verify_record(e) {
    if (this.state.username === "" || this.state.password === "") {
      return;
    }
    e.preventDefault();
    let input = {
      username: this.state.username,
      password: this.state.password,
      login_url: this.props.loginUrl,
    };
    if (this.props.loginUrl==="https://www.cvs.com/account/login-responsive.jsp"){
      input.dob = this.state.dob
      input.firstName = this.state.firstName
      input.lastName = this.state.lastName
    }
    this.props.fetchData("/instant-verification", input);
    this.props.switchScreen("LoadingScreen");
  }

  render() {
    return (
      <VaxContainer>
        <VaxHeader
          canGoBack={true}
          goBack={() => this.props.switchScreen("SelectProvider")}
        />
        <Form>
          <div className="instant-verification-screen">
            <div className="d-flex justify-content-center mt-5 mb-4">
              <img
                src={instsantVerificationMainImage}
                alt=""
                className="logo-img"
                style={{height:"35vh"}}
              ></img>
            </div>
            <Container>
              <h6>
                {I18n.get('instantVerification.description')}
              </h6>

              <div className="inputwrapper">
                <h5 className="title">{I18n.get('instantVerification.formHeader')}</h5>
                {(this.props.loginUrl==="https://www.cvs.com/account/login-responsive.jsp")&&<TextInput
                  required={true}
                  placeholder={I18n.get('instantVerification.firstName')}
                  icon="ni ni-circle-08"
                  value={this.state.firstName}
                  onChange={(e) => {
                    this.setState({ firstName: e.target.value });
                  }}
                  style={{marginBottom:0}}
                />}
                {(this.props.loginUrl==="https://www.cvs.com/account/login-responsive.jsp")&&<TextInput
                  required={true}
                  placeholder={I18n.get('instantVerification.lastName')}
                  icon="ni ni-circle-08"
                  value={this.state.lastName}
                  onChange={(e) => {
                    this.setState({ lastName: e.target.value });
                  }}
                  style={{marginBottom:0, marginTop: 0}}
                />}
                {(this.props.loginUrl==="https://www.cvs.com/account/login-responsive.jsp")&&<DobInput
                  required={true}
                  placeholder={I18n.get('instantVerification.dob')}
                  icon="ni ni-calendar-grid-58"
                  value={this.state.dob}
                  onChange={(e) => {
                    this.setState({ dob: e.target.value });
                  }}
                  style={{marginBottom:0,marginTop: 0}}
                />}
                <div className="text-input-wrapper">
                  <div className="text-icon-left">
                    <img src={userIcon} alt=""></img>
                  </div>
                  <input
                    placeholder={(this.props.loginUrl==="https://www.cvs.com/account/login-responsive.jsp") ? I18n.get('instantVerification.email') : I18n.get('instantVerification.username')}
                    className="text-input"
                    onChange={(e) =>
                      this.setState({ username: e.target.value })
                    }
                    required={true}
                  />
                </div>

                <div className="text-input-wrapper">
                  <div className="text-icon-left">
                    <img src={idIcon} alt=""></img>
                  </div>
                  <input
                    placeholder="Password"
                    className="text-input"
                    type="password"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    required={true}
                  />
                </div>
              </div>
            </Container>
            <BlueButton onClick={(e) => this.verify_record(e)} type="submit">
              VERIFY
            </BlueButton>
          </div>
        </Form>
      </VaxContainer>
    );
  }
}

export default InstantVerificationScreen;
