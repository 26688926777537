import React, { useEffect, useState } from "react";
// import { useHistory } from 'react-router-dom';
// import { routes } from './moduleRoutes';
import Flickity from 'flickity/dist/flickity.pkgd.min.js';
import $ from 'jquery';
import emailjs from 'emailjs-com';
import { I18n } from 'aws-amplify';

const url = "https://ksc8uhwyq2.execute-api.us-east-1.amazonaws.com/prod";

const HomepageModule = (props) => {
	// const { user } = props;
    // const history = useHistory();

    const handleScroll = () => {
    	if (window.scrollY > 50) {
    		$('#themeheader').addClass('is-scrolling');
    	} else {
    		$('#themeheader').removeClass('is-scrolling');
    	}
    }

    useEffect(() => {
    	$("#toggleMenuBtn").on('click', () => {
    		$('#toggleMenuBtn').toggleClass("is-active");
    		$('.navigation ul.menu').toggleClass("is-active");
    	});
    	window.addEventListener('scroll', handleScroll);

    	var flky = new Flickity( '.vision-swiper', {
		  cellAlign: 'left',
		  contain: true,
		  pageDots: true,
		  autoPlay: 7000,
		  prevNextButtons: false
		});

		var newsflky = new Flickity( '.news-swiper', {
		  cellAlign: 'left',
		  contain: true,
		  pageDots: true,
		  autoPlay: 5000,
		  prevNextButtons: false
		});

    	return () => (window.removeEventListener('scroll', handleScroll));

    }, []);

    const [size, setSize] = useState({
      x: window.innerWidth,
      y: window.innerHeight
    });
    const updateSize = () =>
      setSize({
        x: window.innerWidth,
        y: window.innerHeight
      });
    useEffect(() => (window.onresize = updateSize), []);


    const [nameInput, setNameInput] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [sentMessage, setSentMessage] = useState(false)

    const sendMessage = (e) => {
      try{
        if ((email!=="")&&(nameInput!=="")&&(phone!=="")&&(message!=="")){
          e.preventDefault();
          const data = {
            email: email,
            nameInput: nameInput,
            phone: phone,
            message: message
          };

          // fetch(url + "/send-message", {
          //   method: "POST",
          //   cache: "no-cache",
          //   mode: "cors",
          //   body: JSON.stringify(data),
          // }).then(res=>console.log(res))
          // .catch(err=>console.log(err));
          emailjs.init("user_P3YnbWGUMPCmYrIXyMAzz");
          emailjs.send('service_d3kgn2a','template_0s2ftun',data).then(res=>console.log(res)).catch(err=>console.log(err))
          setSentMessage(true);
        }
      }
      catch(err){
        console.log(err)
      }
    }
	// useEffect(() => {
	// 	console.log('in homepage');
	// 	if(user)
	// 		history.push(routes.dashboard);
	// 	else
	// 		history.push(routes.login);
	// }, [history]);
    return (
    	<>
    	<div className="section-hero">
		   <img src="assets/images/hero-main-new.png" alt="" className="view-above640" />
		   <img src="assets/images/mb-hero.png" alt="" className="view-below639" style={{height:800}}/>
       {(size.x>1000)?
         <div className="hero-cpation">
         <div className="container">
           <div className="row">
             <div className="col-6">
               <div className="hero-caption-intro">
	   			 <h1 style={{fontSize:"6em"}}>{I18n.get('heroTitle')}</h1>
                 <h3 style={{marginTop:90}}>
	   				{I18n.get('heroHeadline')} <a href="/registration">{I18n.get('heroHeadlineLink')}</a>.
                 </h3>
	   			<p>{I18n.get('heroComingToAppstore')}</p>
				<p>
				{/*<a href="#">
					<img src="assets/images/app-store.png" alt="" />
				</a>*/}
				</p>
               </div>
             </div>
           </div>
         </div>
        </div>
         :
         <div className="hero-cpation">
  		    <div className="container">
  		      <div className="row">
  		        <div className="col-6">
  		          <div className="hero-caption-intro">
  		            <h1 style={{fontSize:"3.5em", textAlign:"center"}}>{I18n.get('heroTitle')}</h1>
  		            <h3 style={{marginTop:5, textAlign:"center"}}>
					  {I18n.get('heroHeadline')} <a href="/registration">{I18n.get('heroHeadlineLink')}</a>.
  		            </h3>
  		            <p>
  		              {/*<a href="#">
  		              	<img src="assets/images/app-store.png" alt="" />
  		              </a>*/}
  		            </p>
  		          </div>
  		        </div>
              	<p style={{position:"absolute", bottom: 0, width: "50%", color: "#fff", lineHeight: "20px",letterSpacing: ".1em", marginLeft: 20}}>{I18n.get('heroComingToAppstore')}</p>
  		      </div>
  		    </div>
  		   </div>
       }
		</div>

		<div className="section-hwork" id="hwork" style={{overflow:"hidden"}}>
		    <div>
		      <div className="row">
		        <div className="col-12">
		          <div className="section-header" style={{marginTop:30}}>
		            <h2>{I18n.get('howItWorksTitle')}</h2>
		          </div>
		          <div className="how-itwork-warp">
		            <div className="how-itwork-box arrow-shap-left">
		              <div className="how-itwork-box-inner">
		                <figure>
		                  <img src="assets/images/how-work1.png" alt="" style={{height:248, maxWidth:10000}} />
		                </figure>
		                <h3>
							{I18n.get('howItWorks1Line1')}<br/>
							{I18n.get('howItWorks1Line2')}
		                </h3>
		              </div>
		              <span className="shap">
		                <img src="assets/images/arrow.png" alt="" />
		              </span>
		            </div>
		            <div className="how-itwork-box  arrow-shap-left2">
		              <div className="how-itwork-box-inner">
		                <figure>
		                  <img src="assets/images/how-work2.png" alt="" style={{height:248, maxWidth:10000}} />
		                </figure>
		                <h3>
							{I18n.get('howItWorks2Line1')}<br/>
							{I18n.get('howItWorks2Line2')}
						</h3>
		              </div>
		              <span className="shap">
		                <img src="assets/images/arrow.png" alt="" />
		              </span>
		            </div>
		            <div className="how-itwork-box shap-gutter-right">
		              <div className="how-itwork-box-inner">
		                <figure>
		                  <img src="assets/images/how-work3.png" alt="" style={{height:248}}/>
		                </figure>
		                <h3>
							{I18n.get('howItWorks3Line1')}<br/>
							{I18n.get('howItWorks3Line2')}<br/>
							{I18n.get('howItWorks3Line3')}<br/>
							{I18n.get('howItWorks3Line4')}
						</h3>
		              </div>
                  <span className="shap">
		                <img src="assets/images/arrow.png" alt="" />
		              </span>
		            </div>
		            <div className="how-itwork-box shap-gutter-left">
		              <div className="how-itwork-box-inner">
		                <figure>
		                  <img src="assets/images/how-work5.png" alt="" style={{height:248, zIndex:-100}}/>
		                </figure>
		                <h3>
							{I18n.get('howItWorks4Line1')}<br/>
							{I18n.get('howItWorks4Line2')}
						</h3>
		              </div>
		            </div>

		            <div className="section-bottom-box">
		              <div className="section-bottom-main">
		                <span className="icon">
		                  <img src="assets/images/passport-icon.png" alt="" />
		                </span>
		                <div className="intro-text">
							<p>{I18n.get('howItWorksBottom')}</p>
		                </div>
		              </div>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		</div>

		{/*<div className="section-partners" id="partners">
		    <div className="container">
		      <div className="row">
		        <div className="col-12">
		          <div className="partners-box">
		            <div className="section-header">
		              <h2>Partners</h2>
		            </div>
		            <ul className="partners-list">
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo1.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo2.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo3.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo4.png"  alt="" />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo5.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo6.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo7.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo8.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo9.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo10.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo11.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo12.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo13.png" alt=""  />
		                </figure>
		              </li>
		              <li>
		                <figure>
		                  <img src="assets/images/client-logo14.png" alt=""  />
		                </figure>
		              </li>
		            </ul>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>*/}

		  <div className="section-vision" id="our-vision">
		    <div className="section-header">
		      <h2>{I18n.get('visionTitle')}</h2>
		    </div>
		    <div className={(size.x>1000)?"section-vision-main":""}>
  		    <div className="container">
  		      <div className="row">
  		        <div className="col-12">
  		          <div className="vision-box">

        		      <div className="vision-swiper" >
        		        <div className="swiper-item">
        		          <div className="swiper-intro">
        		            <div className="swiper-intro-inner">
        		              <p>
        		                {I18n.get('vision1Para1')}
        		              </p>
        		              <p>{I18n.get('vision1Para2')}</p>
        		            </div>
        		          </div>
        		        </div>
        		        <div className="swiper-item">
        		          <div className="swiper-intro">
        		            <div className="swiper-intro-inner">
							  <p>
        		                {I18n.get('vision2Para1')}
        		              </p>
        		              <p>{I18n.get('vision2Para2')}</p>
        		            </div>
        		          </div>
        		        </div>
        		        <div className="swiper-item">
        		          <div className="swiper-intro">
        		            <div className="swiper-intro-inner">
        		              <p>
		  						{I18n.get('vision3Para1')}
        		              </p>
							  <p>{I18n.get('vision3Para2Start')} <a href="/registration">{I18n.get('vision3Para2Link')}</a>{I18n.get('vision3Para2End')}</p>
        		            </div>
        		          </div>
        		        </div>
        		      </div>

  		          </div>
  		       </div>
  		      </div>
  		    </div>
		    </div>
        {
          !(size.x>1000)&&<img src="assets/images/article-media.png" style={{width:"100%"}}/>
        }
		</div>

    <div id="status" style={{overflow:"hidden"}}>
		  <div >
		    <div className={(size.x>1000)?"row":"column"}>
		      <div className="col-12" style={{display:"flex", flex: 0.5, backgroundColor: "#ECF6F0", alignItems:"center", justifyContent:"center"}}>
		        <div className="section-header" style={{width:"70%"}}>
					<img src="assets/images/green-check.png" alt="" style={{height:128}}/>
					<h3 style={{color:"#27AE60", fontWeight:"bold", marginBottom:30}}>
						{I18n.get('verification1Title')}
					</h3>
					<p style={{fontFamily: "Montserrat"}}>
						{I18n.get('verification1Para1Start')} <strong>{I18n.get('verification1Para1Bold')}</strong> {I18n.get('verification1Para1End')}
						<br/><br/>
						{I18n.get('verification1Para2Start')} <strong>{I18n.get('verification1Para2Bold')}</strong> {I18n.get('verification1Para2End')}
						<br/><br/>
						{I18n.get('verification1Para3Start')} <a href="mailto:support@vax-pass.org">support@vax-pass.org</a> {I18n.get('verification1Para3End')}
					</p>
		        </div>
		      </div>
          <div className="col-12 status-explanation" style={{display:"flex", flex: 0.5, backgroundColor: "#FEFAEF", alignItems:"center", justifyContent:"center"}}>
		        <div className="section-header" style={{width:"50%"}}>
					<img src="assets/images/yellow-check.png" alt="" style={{height:128}}/>
					<h3 style={{color:"#F2C94C", fontWeight:"bold",marginBottom:30}}>
						{I18n.get('verification2Title')}
					</h3>
					<p style={{fontFamily: "Montserrat"}}>
						{I18n.get('verification2Para1Start')} <strong>{I18n.get('verification2Para1Bold')}</strong> {I18n.get('verification2Para1End')}
						<br/><br/>
						{I18n.get('verification2Para2Start')} <strong>{I18n.get('verification2Para2Bold')}</strong> {I18n.get('verification2Para2End')}
						<br/><br/>
						{I18n.get('verification2Para3')}
					</p>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>

		<div className="section-privacy-safety" id="privacy-safety">
		  <div className="container">
		    <div className="row">
		      <div className="col-12">
		        <div className="section-header">
		          <h2>{I18n.get('privacyTitle')}</h2>
		        </div>
		      </div>
		    </div>
		    <div className="row align-items-center gutter-tb">
		      <div className="col-12 col-md-12 col-lg-6">
		        <figure>
		          <img src="assets/images/article-media2.png" alt="" />
		        </figure>
		      </div>
		      <div className="col-12 col-md-12 col-lg-6">
		        <div className="section-intro">
		          <p><strong>{I18n.get('privacy1Title')}</strong> {I18n.get('privacy1Content')}</p>
				  <p><strong>{I18n.get('privacy2Title')}</strong> {I18n.get('privacy2Content')}</p>
				  <p><strong>{I18n.get('privacy3Title')}</strong> {I18n.get('privacy3Content')}</p>
				  <p><strong>{I18n.get('privacy4Title')}</strong> {I18n.get('privacy4Content')}</p>
				  <p><strong>{I18n.get('privacy5Title')}</strong> {I18n.get('privacy5Content')}</p>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>

		<div className="section-news" id="news">
		  <div style={{padding:30}}>
		    <div className="row">
		      <div className="col-12">
		        <div className="section-header">
		          <h2>{I18n.get('newsTitle')}</h2>
		        </div>

		        <div className="news-swiper" >
		          <div className="news-swiper-item">
		            <div className="news-swiper-item-inner">
		              <div className="date-time">March 1, 2021  |  Tech crunch</div>
					  <h3>{I18n.get('news1Heading')}</h3>
		              <p>{I18n.get('news1Body')}</p>
		              <div className="more-details">
		                <a href="https://techcrunch.com/2021/03/01/eu-to-propose-a-digital-pass-for-covid-19-vaccination-test-status-to-help-safer-travel">
		                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		                    <path d="M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z" fill="#7FBDE0"/>
		                    </svg>
		                </a>
		              </div>
		            </div>
		          </div>
		          <div className="news-swiper-item">
		            <div className="news-swiper-item-inner">
		              <div className="date-time">March 4, 2021  |  New York Theater Guide</div>
		              <h3>{I18n.get('news2Heading')}</h3>
		              <p>{I18n.get('news2Body')}</p>
		              <div className="more-details">
		                <a href="https://www.newyorktheatreguide.com/news-features/broadway-theaters-to-reopen-as-part-of-ny-popsup-festival">
		                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		                    <path d="M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z" fill="#7FBDE0"/>
		                    </svg>
		                </a>
		              </div>
		            </div>
		          </div>
		          <div className="news-swiper-item">
		            <div className="news-swiper-item-inner">
		              <div className="date-time">March 5, 2021  |  Austin American-Statestman</div>
		              <h3>{I18n.get('news3Heading')}</h3>
		              <p>{I18n.get('news3Body1')}</p>
		              <p>
		                {I18n.get('news3Body2')}</p>
		              <div className="more-details">
		                <a href="https://www.statesman.com/story/news/coronavirus/2021/03/05/texas-covid-vaccine-totals-pandemic-year/4579588001/">
		                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		                    <path d="M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z" fill="#7FBDE0"/>
		                    </svg>
		                </a>
		              </div>
		            </div>
		          </div>
		          <div className="news-swiper-item">
		            <div className="news-swiper-item-inner">
		              <div className="date-time">March 7, 2021  | NYTimes</div>
		              <h3>{I18n.get('news4Heading')}</h3>
		              <p>{I18n.get('news4Body')}</p>
		              <div className="more-details">
		                <a href="https://www.nytimes.com/live/2021/03/07/world/covid-19-coronavirus">
		                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		                    <path d="M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z" fill="#7FBDE0"/>
		                    </svg>
		                </a>
		              </div>
		            </div>
		          </div>
		          <div className="news-swiper-item">
		            <div className="news-swiper-item-inner">
		              <div className="date-time">March 14, 2021  |  BBC</div>
		              <h3>{I18n.get('news5Heading')}</h3>
		              <p>{I18n.get('news5Body')}</p>
		              <div className="more-details">
		                <a href="https://www.bbc.com/news/business-56392570">
		                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		                    <path d="M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z" fill="#7FBDE0"/>
		                    </svg>
		                </a>
		              </div>
		            </div>
		          </div>

		        </div>
		      </div>

		    </div>
		  </div>
		</div>

		<div className="section-touch" id="contact">
		  <div className="container">
		    <div className="row">
		      <div className="col-12">
		        <div className="section-header">
		          <h2>{I18n.get('getInTouch')}</h2>
		        </div>
		        <div className="get-touch-warp">
		          <ul>
		            <li>
		              <div className="content-box">
		                <div className="content-box-inner">
                    <a href="https://www.google.com/maps/place/343+E+51st+St,+New+York,+NY+10022/data=!4m2!3m1!1s0x89c258e233a425c3:0xa399eb0205f428c2?sa=X&ved=2ahUKEwixtPO04LfvAhWzRjABHQhTDXYQ8gEwAHoECAYQAQ">
		                  <div className="icon">
		                    <img src="assets/images/location-icon.png" alt="" style={{height: 64}}/>
		                  </div>
		                  <address>343 E 51st St,<br/>
		                    New York,<br/>
		                    NY</address>
                    </a>
		                </div>
		              </div>
		            </li>
		            <li>
		              <div className="content-box">
		                <div className="content-box-inner">
                    <a href="tel:+17205732717">
		                  <div className="icon">
		                    <img src="assets/images/phone-icon.png" alt="" style={{height: 64}}/>
		                  </div>
		                  <p>(720) 573-2717</p>
                    </a>
		                </div>
		              </div>
		            </li>
		            <li>
		              <div className="content-box">
		                <div className="content-box-inner">
                    <a href="mailto:contact@vax-pass.org">
		                  <div className="icon">
		                    <img src="assets/images/email-icon.png" alt="" style={{height: 64}}/>
		                  </div>
		                  <p>contact@vax-pass.org</p>
                    </a>
		                </div>
		              </div>
		            </li>
		          </ul>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>

		<div className="section-form">
		  <div className="container">
		    <div className="row">
		      <div className="col-12">
		        <div className="section-header">
		          <h3>{I18n.get('sendMessageTitle')}</h3>
		        </div>
		        <div className="form-warp">
		          <form>
		            <div className="col-12 col-md-12 col-lg-6">
		              <div className="input-box">
		                <input type="text" value="" id="name" className="input-name" placeholder={I18n.get('sendMessageName')} required={true} value={nameInput} onChange={(e)=>setNameInput(e.target.value)}/>
		              </div>
		              <div className="input-box">
		                <input type="email" value="" id="email" className="input-email" placeholder={I18n.get('sendMessageEmail')} required={true} value={email} onChange={(e)=>setEmail(e.target.value)}/>
		              </div>
		              <div className="input-box">
		                <input type="tel" id="phone" className="input-tel" name="phone" placeholder={I18n.get('sendMessagePhone')} required={true} value={phone} onChange={(e)=>setPhone(e.target.value)}/>
		              </div>
		            </div>
		            <div className="col-12 col-md-12 col-lg-6">
		              <div className="input-box">
		                <textarea id="message" className="input-details" placeholder={I18n.get('sendMessageContent')} required={true} value={message} onChange={(e)=>setMessage(e.target.value)}></textarea>
		              </div>
		            </div>
		            <div className="col-12" onClick={sendMessage}>
		              <div className="input-box">
		                <button type="submit" className="btn btn-primary" style={{color:"#fff", backgroundColor:"#007cc3", borderColor:"#007cc3"}} disabled={sentMessage}>{(sentMessage)?I18n.get('sendMessageSentButton'):I18n.get('sendMessageButton')}</button>
		              </div>
		            </div>
		          </form>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>

    	</>
    	);
}

export default HomepageModule;
