const zh = {
'heroTitle': 'Vax Pass',
'heroHeadline': '使用Vax Pass安全地返回生活并旅行。得到你的通行证',
'heroHeadlineLink': '这里',
'heroComingToAppstore': '扫描应用程序即将在App Store上提供',
'howItWorksTitle': '怎么运行的',
'howItWorks1Line1': '实验室测试/疫苗接种',
'howItWorks1Line2': '验证之前',
'howItWorks2Line1': '自动验证',
'howItWorks2Line2': '实验室结果/疫苗接种',
'howItWorks3Line1': 'Vax Pass保存到',
'howItWorks3Line2': '苹果钱包',
'howItWorks3Line3': 'Google Pay，或',
'howItWorks3Line4': '作为PDF',
'howItWorks4Line1': '企业扫描通行证',
'howItWorks4Line2': '验证客户身份和健康状况',
'howItWorksBottom': 'Vax Pass遵循CDC指南，以最大程度地减少Covid-19传播的风险。检查CDC的Covid-19网站以获取最新更新。',
'visionTitle': '我们的愿景',
'vision1Para1': '现在，我们对Covid-19的了解可以帮助我们恢复正常。通过制定明智的政策，我们可以通过将风险降低到可接受的水平来重新开放经济。如果安全和负责任地开展工作，整个社区将很快获得畜群免疫力。',
'vision1Para2': '我们希望我们的产品能够使各种业务快速安全地重新开放。',
'vision2Para1': '然后，使用Vax Pass的企业可以安全地重新开放，从而使大部分经济领域重新活跃起来。 Vax Pass可以协助的弱项业务包括体育馆，夜总会，酒吧和航空公司。',
'vision2Para2': '随着全球疫苗接种进程的加快，Vax Pass将使人们能够安全地参与其经济活动并恢复到新的“正常状态”。',
'vision3Para1': '如果我们希望停止Covid的传播并将其谴责到过去，那么我们需要明智而明智的政策来指导我们。通过Vax Pass，我们可以缓慢而可靠地朝着这个目标前进。',
'vision3Para2Start': '加入我们',
'vision3Para2Link': '立即获得Vax Pass',
'vision3Para2End': '，不久将在全球范围内提供支持。',
'verification1Title': '数字验证',
'verification1Para1Start': '数字验证会生成一个',
'verification1Para1Bold': '绿色通行证',
'verification1Para1End': '从本质上讲，这是不能伪造的。通过验证记录是否存在于医疗机构的记录中，可以确保通行证的有效性。',
'verification1Para2Start': '目前，这种验证方法',
'verification1Para2Bold': '支持600多家医疗机构',
'verification1Para2End': '全美有超过1亿患者。在此处查看当前是否支持医疗机构。',
'verification1Para3Start': '接触',
'verification1Para3End': '以获取有关扩大对其他医疗机构的支持的信息。',
'verification2Title': '图片验证码',
'verification2Para1Start': '图像验证会生成一个',
'verification2Para1Bold': '黄色通行证',
'verification2Para1End': '，使企业可以为其客户提供额外的安全保护。',
'verification2Para2Start': '这通过',
'verification2Para2Bold': '最先进的机器学习技术',
'verification2Para2End': '自动验证病历图像。',
'verification2Para3': '手动筛选了10％的记录是否存在欺诈行为，从而对伪造记录的人造成后果。',
'privacyTitle': '隐私与安全',
'privacy1Title': 'Vax Pass',
'privacy1Content': '旨在增强用户体验，同时最大程度地减少Covid传输的风险：',
'privacy2Title': 'CDC指南：',
'privacy2Content': '知道所有在场人员均已通过验证，因此成功遵循CDC准则',
'privacy3Title': '云技术：',
'privacy3Content': '使用最新的Amazon云技术，Vax Pass可在快速，安全的系统上运行',
'privacy4Title': '全球支持：',
'privacy4Content': 'Vax Pass即将在全球范围内被接受为Covid-19疫苗接种证明',
'privacy5Title': '数字签名：',
'privacy5Content': '审查了多个数字足迹，以确保通行证有效性',
'newsTitle': '消息',
'news1Heading': '欧盟提议为COVID-19疫苗接种/测试状态提供“数字通行证”，以...',
'news1Body': '欧盟委员会表示，将在本月晚些时候提出一项立法计划，称其为“数字绿色通行证”（又称数字证书），称其目的是促进冠状病毒时代的跨境旅行。',
'news2Heading': '百老汇剧院将重新开放，成为纽约PopsUp音乐节的一部分',
'news2Body': '在宣布在纽约市举行的艺术流行艺术节之后，州长Cuomo宣布将于4月2日恢复室内现场表演。',
'news3Heading': '得克萨斯州全面接种疫苗的人将很快在全州通过确诊病例',
'news3Body1': '在该州首例确诊的COVID-19病例一年后，得克萨斯州完全接种疫苗的人数将很快超过全州报告的冠状病毒病例总数。',
'news3Body2': '超过7％的德克萨斯人，即2,177,635人，全部...',
'news4Heading': '以色列重新向完全接种疫苗的“绿色通行证”持有人开放-几乎一半的...',
'news4Body': '以色列总理本杰明·内塔尼亚胡周日早上在耶路撒冷一家咖啡馆的露台上享用卡布奇诺咖啡和蛋糕，以庆祝该国经济自一年前首次封锁以来最大规模的重新开放。',
'news5Heading': '英国航空计划基于应用程序的旅行通行证',
'news5Body': '根据该计划，同时患有两个刺刺的人将能够在BA的智能手机应用程序中注册其身份。该航空公司的首席执行官肖恩·道尔（Sean Doyle）赞扬英国在应对这一流行病方面的“巨大进步”。',
'getInTouch': '保持联系',
'sendMessageTitle': '发送信息',
'sendMessageName': '你的名字',
'sendMessageEmail': '电子邮件',
'sendMessagePhone': '你的电话号码',
'sendMessageContent': '写您的留言...',
'sendMessageButton': '发信息',
'sendMessageSentButton': '发送',
'headerHome': '家',
'headerHowItWorks': '怎么运行的',
'headerStatus': '状态',
'headerVision': '我们的愿景',
'headerPrivacy': '隐私与安全',
'headerNews': '消息',
'headerContact': '接触',
'footerHowItWorks': '状态',
'footerStatus': '我们的愿景',
'footerVision': '隐私与安全',
'footerPrivacy': '消息',
'footerNews': '接触',
'footerContact': '抱歉-发生错误',
'error.occured': '回家',
'error.homeButton': '验证您的错误',
'verification.errorStart': '新冠肺炎',
'verification.errorBold': '记录',
'verification.errorEnd': '尝试图像验证',
'verification.tryImage': '图像验证画面',
'imageVerification.header': '名',
'imageVerification.firstName': '姓',
'imageVerification.lastName': '电子邮件地址',
'imageVerification.email': '出生日期（月/日/年）',
'imageVerification.dob': '选择图片',
'imageVerification.selectImage': '所选图片',
'imageVerification.imageSelected': 'PCR检测结果或疫苗接种卡',
'imageVerification.imageDescription': '请上传有效的图片',
'imageVerification.imageInvalid': '提交',
'imageVerification.submit': 'Vax Pass可让患者暂时分享其部分健康摘要，包括免疫接种',
'instantVerification.description': '输入您的信息',
'instantVerification.formHeader': '名',
'instantVerification.firstName': '姓',
'instantVerification.lastName': '出生日期（月/日/年）',
'instantVerification.dob': '电子邮件',
'instantVerification.email': '用户名',
'instantVerification.username': '密码',
'instantVerification.password': '企业使用',
'introScreen.description1Start': '增值税通行证',
'introScreen.description1Heading': '验证您已针对Covid-19进行了疫苗接种或测试阴性',
'introScreen.description1End': '安全的',
'introScreen.description2Head': '数据的传输是加密的并且安全的。',
'introScreen.description2Body': '私人的',
'introScreen.description3Head': '验证数据后，将无法访问您的病历。',
'introScreen.description3Body': '点击“下一步”即表示您同意我们的',
'introScreen.tosStart': '服务条款',
'introScreen.tosLink1': '和',
'introScreen.tosMiddle': '隐私政策',
'introScreen.tosLink2': '下一个',
'introScreen.next': '平均等待时间',
'loading.averageWaitTime': '秒',
'loading.seconds': '上载中...',
'loading.uploadingTitle': '验证中...',
'loading.verifyingTitle': '正在上传您的图片进行验证...。',
'loading.uploadingBody': '验证疫苗接种记录...',
'loading.verifyingBody': '感谢您选择我们。',
'pdfPass.text1': '这是您的Vax Pass，可进入Vax Pass区域。您的通行证已被批准并确认。如有任何疑问，请发送邮件至contact@vax-pass.org',
'pdfPass.text2': '请访问www.vax-pass.org，以了解Vax Pass的工作原理。',
'pdfPass.text3': '要将此通行证添加到钱包，请打开我们在Apple或Android设备上发送给您的电子邮件。',
'pdfPass.text4': '测试日期',
'pdfPass.validityExpression': '测试日期',
'pdfPass.invalid': '生效后',
'pdfPass.validAfter': '有效期至',
'pdfPass.validUntil': '姓名',
'pdfPass.name': '出生日期',
'pdfPass.dob': '选择医疗提供者',
'selectProvider.title': '搜索提供商（即锥体健康）',
'selectProvider.searchText': 'CVS',
'selectProvider.cvs': '我的图表',
'selectProvider.myChart': '选择验证类型',
'selectVerification.title': '数字的',
'selectVerification.digitalTitle1': '确认',
'selectVerification.digitalTitle2': '通过您的疫苗提供者',
'selectVerification.digitalBody1': '（仅疫苗）',
'selectVerification.digitalBody2': '图像',
'selectVerification.imageTitle1': '确认',
'selectVerification.imageTitle2': '通过疫苗接种或',
'selectVerification.imageBody1': 'PCR检测结果图',
'selectVerification.imageBody2': '恭喜，您的记录已成功验证！',
'submission.success': '继续',
'submission.continue': '请输入有效的电子邮件',
'verifiedRecord.invalidEmail': '保存您的VaxPass',
'verifiedRecord.title': '电子邮件',
'verifiedRecord.email': '发送至电子邮件',
'verifiedRecord.send': '邮件已发送',
'verifiedRecord.sent': '保存到苹果钱包',
'verifiedRecord.saveApple': '保存到电话',
'verifiedRecord.savePhone': '注意：您的通行证只有效至',
'registration.notValidUntil': '您的疫苗未经验证。请改为尝试图像验证。',
'registration.validTill': '已确认您的PCR阴性结果，但是您的通行证无效。要获得有效的通行证，请提交过去72小时内的否定结果。',
'registration.notVerified': '上传您的数据时出错。请重新加载此页面，然后重试。',
'registration.passNotValid': '抱歉，验证您的图片时出错',
'registration.errorUploading': '我们找不到您要寻找的页面',
'successfulSubmissionText': '恭喜，您的记录已成功上传！请最多等待24小时才能验证您的记录。验证完成后，我们将发送电子邮件.'
};
 export default zh;
