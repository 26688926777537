import "../assets/css/template.min.css";
import "flickity/dist/flickity.min.css";
import Layout from './components/Layout';
import HomePage from './modules/homePage';

const App = () => {
  return(
    <Layout>
      <HomePage />
    </Layout>
  )
}

export default App
