import React from "react";
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import "./stylesheets/SubmissionScreen.scss";
import successImage from "../assets/img/vaxpass/successImage.png";
import { BlueButton } from "./Buttons";
import { I18n } from 'aws-amplify';

class SubmissionScreen extends React.Component {
  render() {
    return (
      <VaxContainer>
        <VaxHeader canGoBack={false} />
        <div>
          <div className="d-flex justify-content-center">
            <div className="mt-5 mb-4 image-wrapper">
              <img src={successImage} className="mb-3 mx-auto intro-screen-img" alt="Error" style={{width:"40vh"}}/>
            </div>
          </div>

          <div className="my-4">
            <h6>
              {I18n.get('submission.success')}
            </h6>
          </div>
          <div className="my-5">
            <BlueButton
              onClick={() => this.props.switchScreen("VerifiedRecord")}
            >
              {I18n.get('submission.continue')}
            </BlueButton>
          </div>
        </div>
      </VaxContainer>
    );
  }
}

export default SubmissionScreen;
