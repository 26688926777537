import React from 'react';
// import { Link } from 'react-router-dom';
// import {Row, Col} from 'react-bootstrap';

// import StaticMenu from "./StaticMenu";
// import UserMenu from "./UserMenu";
// import { routes } from '../../modules/moduleRoutes';
// import { toggleMenu } from "./helpers";
import { I18n } from 'aws-amplify';

const Header = (props) => {
    // const {
    //     user,
    // } = props;

    // const logo = (
    //     <div id={"top"}>
    //         <Link to={(user != null) ? routes.dashboard : routes.homepage}>
    //             <NavigationLogo />
    //         </Link>
    //     </div>
    // );

   // const menu = (user != null) ? <UserMenu user={user} /> : <StaticMenu />;

    return (
        <header className="theme-header header-fix" id="themeheader">
            <div className="container">
              <div className="row">
                <div className="header-col">
                  <div className="brand-logo">
                    <a href="#">
                      <img src="https://screenshot-files-k1999.s3.amazonaws.com/VaxPassLogo2.png" alt="" style={{height:40,minHeight:40,minWidth:200}}/>
                    </a>
                  </div>
                  <div className="nav-col">
                    <nav className="navigation">
                      <div className="nav-toggle-btn">
                        <button type="button" className="toggle-menu-btn" id="toggleMenuBtn">
                          <span className="line sp1"></span>
                          <span className="line sp2"></span>
                          <span className="line sp3"></span>
                        </button>
                      </div>
                      <ul className="menu">
                        <li>
                          <a href="#">{I18n.get('headerHome')}</a>
                        </li>
                        <li>
                          <a href="#hwork">{I18n.get('headerHowItWorks')}</a>
                        </li>
                        <li>
                          <a href="#status">{I18n.get('headerStatus')}</a>
                        </li>
                        <li>
                          <a href="#our-vision">{I18n.get('headerVision')}</a>
                        </li>
                        <li>
                          <a href="#privacy-safety">{I18n.get('headerPrivacy')}</a>
                        </li>
                        <li>
                          <a href="#news">{I18n.get('headerNews')}</a>
                        </li>
                        <li>
                          <a href="#contact">{I18n.get('headerContact')}</a>
                        </li>

                      </ul>
                    </nav>
                    {/*<div className="social-icon">
                      <ul>
                        <li>
                          <a href="#">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M21.995 22.0001V21.9993H22V14.6643C22 11.0759 21.2275 8.31177 17.0325 8.31177C15.0158 8.31177 13.6625 9.41843 13.11 10.4676H13.0516V8.64677H9.07414V21.9993H13.2158V15.3876C13.2158 13.6468 13.5458 11.9634 15.7016 11.9634C17.8258 11.9634 17.8575 13.9501 17.8575 15.4993V22.0001H21.995Z" fill="#ffffff"/>
                              <path d="M2.32998 8.64746H6.47664V21.9999H2.32998V8.64746Z" fill="#ffffff"/>
                              <path d="M4.40166 2C3.07583 2 2 3.07583 2 4.40166C2 5.72749 3.07583 6.82582 4.40166 6.82582C5.72749 6.82582 6.80332 5.72749 6.80332 4.40166C6.80249 3.07583 5.72666 2 4.40166 2V2Z" fill="#ffffff"/>
                              </svg>


                          </a>
                        </li>
                        <li>
                          <a href="">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M22 5.92375C21.2562 6.25 20.4637 6.46625 19.6375 6.57125C20.4875 6.06375 21.1363 5.26625 21.4413 4.305C20.6488 4.7775 19.7737 5.11125 18.8412 5.2975C18.0887 4.49625 17.0162 4 15.8462 4C13.5762 4 11.7487 5.8425 11.7487 8.10125C11.7487 8.42625 11.7762 8.73875 11.8438 9.03625C8.435 8.87 5.41875 7.23625 3.3925 4.7475C3.03875 5.36125 2.83125 6.06375 2.83125 6.82C2.83125 8.24 3.5625 9.49875 4.6525 10.2275C3.99375 10.215 3.3475 10.0238 2.8 9.7225C2.8 9.735 2.8 9.75125 2.8 9.7675C2.8 11.76 4.22125 13.415 6.085 13.7962C5.75125 13.8875 5.3875 13.9312 5.01 13.9312C4.7475 13.9312 4.4825 13.9162 4.23375 13.8612C4.765 15.485 6.2725 16.6787 8.065 16.7175C6.67 17.8087 4.89875 18.4662 2.98125 18.4662C2.645 18.4662 2.3225 18.4512 2 18.41C3.81625 19.5812 5.96875 20.25 8.29 20.25C15.835 20.25 19.96 14 19.96 8.5825C19.96 8.40125 19.9537 8.22625 19.945 8.0525C20.7587 7.475 21.4425 6.75375 22 5.92375Z" fill="#ffffff"/>
                              </svg>
                          </a>
                        </li>
                      </ul>
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
        </header>
    );
}

export default Header;
