import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import VaxPassRegistration from "./VaccinePass/VaxPassRegistration.js";
import ProductResults from "./VaccinePass/ProductResultsScreen.js";

import Landing from "./landing/index.js"
import React from "react";
import { I18n } from 'aws-amplify';
import enLang from './locales/en.js';
import esLang from './locales/es.js';
import ruLang from './locales/ru.js';
import zhLang from './locales/zh.js';

import "./assets/vendor/nucleo/css/nucleo.css";
import "./App.scss"

export default class Unauthorized extends React.Component{
  constructor(props) {
    super(props);
    I18n.putVocabularies({
      'en': enLang,
      'es': esLang,
      'ru': ruLang,
      'zh': zhLang
    });

    I18n.setLanguage(navigator.language);
  }

  render(){
    return(
      <BrowserRouter>
        <Switch>
          <Route path="/" exact render={props => <Landing {...props} />} />
          <Route path="/registration" exact render={props => <VaxPassRegistration {...props} />} />
          <Route path="/vui-search" exact render={props => <ProductResults {...props}/> } />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    )
  }
}
