import React from "react";
import { Container, Form } from "reactstrap";
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import { BlueButton, BlackButton } from "./Buttons";
import appleLogo from "../assets/img/vaxpass/appleLogo.svg";
import googleLogo from "../assets/img/vaxpass/googleLogo.svg";
import { TextInput } from "./Inputs";
import { I18n } from 'aws-amplify';

let fake_email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)/
let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/

class VerifiedRecordScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phoneNumber: "",
    };
  }
  googlePlaySave() {
    const link = this.props.recordData.google_pay_vaccine_pass_link;
    window.open(link, "_blank");
  }

  applePaySave() {
    const link = this.props.recordData.apple_wallet_vaccine_pass_link;
    window.open(link, "_blank");
  }

  downloadBarcode() {
    const link = this.props.recordData.pdf_vaccine_pass_link;
    window.open(link, "_blank");
  }

  sendEmail(e){
    if (fake_email_reg.test(this.state.email)==false){
      return
    }
    else if (email_reg.test(this.state.email)==false){
      e.preventDefault()
      alert(I18n.get('verifiedRecord.invalidEmail'))
      return
    }
    else if (this.state.sentEmail){
      e.preventDefault();
      return
    }
    else{
      e.preventDefault();
      this.props.fetchData("/send-email",{
        id: this.props.recordData.id,
        email: this.state.email
      });
      this.setState({sentEmail: true})
    }
  }

  returnBack() {}

  render() {
    return (
      <VaxContainer>
        <VaxHeader canGoBack={false} />
        <Container className="mt-4 mb-5">
          <h5 className="mb-4">{I18n.get('verifiedRecord.title')} {this.props.alertMessage&&`(${this.props.alertMessage})`}</h5>
          <div >
            <Form>
              <TextInput
                placeholder={I18n.get('verifiedRecord.email')}
                icon="ni ni-email-83"
                value={this.state.email}
                required={true}
                type="email"
                onChange={(e) => {
                  this.setState({ email: e.target.value, sentEmail: false });
                }}
              />
              <BlueButton
                className="mb-3"
                buttonStyles={{ padding: "10px 20px" }}
                onClick={(e) => this.sendEmail(e)}
                type="submit"
              >
                <h6 className="mb-0">{(this.state.sentEmail)?I18n.get('verifiedRecord.sent'):I18n.get('verifiedRecord.send')}</h6>
              </BlueButton>
            </Form>
          </div>
          <BlackButton
            className="mb-3"
            buttonStyles={{ padding: "10px 20px" }}
            onClick={() => this.applePaySave()}
          >
            <div className="d-flex justify-content-center">
              <img src={appleLogo} alt="Apple Logo" className="mr-3 ml-2"></img>
              <p className="my-1">
                <b>{I18n.get('verifiedRecord.saveApple')}</b>
              </p>
            </div>
          </BlackButton>
          {/*<BlackButton
            className="mb-3"
            buttonStyles={{ padding: "10px 20px" }}
            onClick={() => this.googlePlaySave()}
          >
            <div className="d-flex justify-content-center">
              <img
                src={googleLogo}
                alt="Google Logo"
                className="mr-3 ml-2"
              ></img>
              <p className="my-1">
                <b>Save to Google Pay</b>
              </p>
            </div>
          </BlackButton>*/}
          <BlueButton
            className="mb-3"
            buttonStyles={{ padding: "10px 20px" }}
            onClick={() => this.downloadBarcode()}
          >
            <h6 className="mb-0">{I18n.get('verifiedRecord.savePhone')}</h6>
          </BlueButton>
        </Container>
      </VaxContainer>
    );
  }
}

export default VerifiedRecordScreen;
