import React from "react";
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import errorImage from "../assets/img/vaxpass/errorImage.png";
import { BlueButton } from "./Buttons";
import { I18n } from 'aws-amplify';

class ErrorVerificationScreen extends React.Component {
  render() {
    return (
      <VaxContainer>
        <VaxHeader canGoBack={false} />
        <div className="mb-5 vax-intro-screen">
          <div className="d-flex">
            <img
              src={errorImage}
              alt=""
              className="mb-3 mx-auto intro-screen-img"
            ></img>
          </div>
          <div className="my-4">
            <h6 className="px-5">
              {I18n.get('verification.errorStart')} <b>{I18n.get('verification.errorBold')}</b> {I18n.get('verification.errorEnd')}.
            </h6>
            <div className="my-5">
              <BlueButton
                onClick={() => this.props.switchScreen("ImageVerification")}
              >
                <p className="mb-0">{I18n.get('verification.tryImage')}</p>
              </BlueButton>
            </div>
          </div>
        </div>
      </VaxContainer>
    );
  }
}

export default ErrorVerificationScreen;
