import React from "react";
// reactstrap components
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import "./stylesheets/IntroScreen.scss";
import introScreenImage from "../assets/img/vaxpass/introScreenImage.png";
import checkmark from "../assets/img/vaxpass/checkmark.svg";
import { Link } from "react-router-dom";
import { BlueButton } from "./Buttons";
import { I18n } from 'aws-amplify';

class WelcomeScreens extends React.Component {
  render() {
    return (
      <VaxContainer>
        <VaxHeader canGoBack={false} />
        <div className="mb-5 vax-intro-screen">
          <div className="d-flex" style={{marginLeft: "2vh"}}>
            <img
              src="https://screenshot-files-k1999.s3.amazonaws.com/intro1.png"
              alt=""
              className="mb-3 mx-auto intro-screen-img"
            ></img>
          </div>
          <h5 className="mt-3 vax-pass-img-caption">
            {I18n.get('introScreen.description1Start')} <h5 className="vax-pass-text">{I18n.get('introScreen.description1Heading')}</h5> {I18n.get('introScreen.description1End')}
          </h5>
          <hr className="divider"></hr>
          <div className="mx-5">
            <div className="d-flex title-text mb-3">
              <img src={checkmark} className="mr-2" alt=""></img>{" "}
              <h5 className="vax-pass-img-caption mb-0">{I18n.get('introScreen.description2Head')}</h5>
            </div>
            <h6 className="text-center text-light">
              {I18n.get('introScreen.description2Body')}
            </h6>
          </div>
          <hr className="divider"></hr>
          <div className="mx-5">
            <div className="d-flex title-text mb-3">
              <img src={checkmark} className="mr-2" alt=""></img>{" "}
              <h5 className="vax-pass-img-caption mb-0">{I18n.get('introScreen.description3Head')}</h5>
            </div>
            <h6 className="text-center text-light">
              {I18n.get('introScreen.description3Body')}
            </h6>
          </div>
          <hr className="divider"></hr>
          <p className="mx-5 mt-3 mb-4">
            {I18n.get('introScreen.tosStart')}{" "}
            <a href="https://cdkworkshop-pathresolverpublicassets48ef0c65-1t8dg8ouzm6cf.s3.amazonaws.com/Terms+of+Service+%26+Privacy+Policy-Vax+Pass.pdf" className="white-text">
              {I18n.get('introScreen.tosLink1')}
            </a>{" "}
            {I18n.get('introScreen.tosMiddle')}{" "}
            <a href="https://cdkworkshop-pathresolverpublicassets48ef0c65-1t8dg8ouzm6cf.s3.amazonaws.com/Terms+of+Service+%26+Privacy+Policy-Vax+Pass.pdf" className="white-text">
              {I18n.get('introScreen.tosLink2')}
            </a>
            .
          </p>
          <div>

          </div>
          <BlueButton
            onClick={() => this.props.switchScreen("SelectVerificationScreen")}
          >
            {I18n.get('introScreen.next')}
          </BlueButton>
        </div>
      </VaxContainer>
    );
  }
}

export default WelcomeScreens;
