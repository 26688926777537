import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import backArrow from "../assets/img/vaxpass/backArrow.svg";
import vaxPassIcon from "../assets/img/vaxpass/vaxPassIcon.svg";
import "./stylesheets/VaxHeader.scss";

export default function VaxHeader(props) {
  const { canGoBack = true, goBack } = props;
  return (
    <div className="vax-header">
      {canGoBack && (
        <Button
          color="link"
          style={{ float: "left" }}
          className="back-button"
          onClick={() => {
            goBack();
          }}
        >
          <div className="quarter-circle-top-left">
            <img src={backArrow} className="arrow" alt="back"></img>
          </div>
        </Button>
      )}
      <div className="logo">
        <a href="/">
          <img src="https://screenshot-files-k1999.s3.amazonaws.com/VaxPassLogo2.png" alt="Vax Pass" style={{height:"5vh"}}></img>
        </a>
      </div>
    </div>
  );
}

VaxHeader.propTypes = {
  toggle: PropTypes.func,
};
