import React from "react";
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import "./stylesheets/LoadingScreen.scss";
import loadingImage from "../assets/img/vaxpass/loadingImage.png";
import { I18n } from 'aws-amplify';

class LoadingScreen extends React.Component {
  render() {
    return (
      <VaxContainer>
        <VaxHeader
          canGoBack={false}
          goBack={() => this.props.switchScreen("SelectVerificationScreen")}
        />
        <div className="vax-loading-screen">
          <h5 className="my-4">{(this.props.verifyingImage) ? I18n.get('loading.uploadingTitle') : I18n.get('loading.verifyingTitle')}</h5>
          <div className="d-flex justify-content-center">
            <div className="loader">
              <div className="spinner"></div>
              <img src={loadingImage} className="logo-img" alt="Loading" />
            </div>
          </div>
          <div className="my-4">
            <p>
              {I18n.get('loading.averageWaitTime')}: <b>{(this.props.verifyingImage)?`5 ${I18n.get('loading.seconds')}`:`30 ${I18n.get('loading.seconds')}`}</b>
            </p>
            <p>{(this.props.verifyingImage) ? I18n.get('loading.uploadingBody') : I18n.get('loading.verifyingBody')}</p>
          </div>
        </div>
      </VaxContainer>
    );
  }
}

export default LoadingScreen;
