const en = {
    heroTitle: 'Vax Pass',
    heroHeadline: 'Safely return to life and travel with Vax Pass. Get your pass',
    heroHeadlineLink: 'here',
    heroComingToAppstore: 'Scanning application soon to be available on the App Store',

    howItWorksTitle: 'How It Works',
    howItWorks1Line1: 'Lab test / vaccination',
    howItWorks1Line2: 'prior to verification',
    howItWorks2Line1: 'Automatic verification of',
    howItWorks2Line2: 'lab results / vaccination',
    howItWorks3Line1: 'Vax Pass is saved to',
    howItWorks3Line2: 'Apple Wallet',
    howItWorks3Line3: 'Google Pay, or',
    howItWorks3Line4: 'as a PDF',
    howItWorks4Line1: 'Businesses scan passes',
    howItWorks4Line2: 'to verify customer identities and health status',
    howItWorksBottom: 'Vax Pass follows CDC guidelines in order to minize the risk of Covid-19 transmission. Check the CDC\'s Covid-19 website for the latest updates.',

    visionTitle: 'Our Vision',
    vision1Para1: 'We are now at a point where our knowledge of Covid-19 can help us in our journey back to normality. By enacting sensible policies we can reopen the economy by reducing risk to acceptable levels. If done safely and responsibly,  entire communities will soon reach herd immunity.',
    vision1Para2: 'We hope that our product allows businesses of all kinds to quickly and safely reopen.',
    vision2Para1: 'Businesses using Vax Pass can then safely reopen allowing large sectors of the economy to reactivate. Among the weakened businesses that Vax Pass can assist are stadiums, night-clubs, bars, and airlines.',
    vision2Para2: 'As the vaccination process speeds up throughout the world, Vax Pass will allow people to safely and securely participate in their economies and return to a new "normal".',
    vision3Para1: 'If we ever hope to stop the spread of Covid and condemn it to the past we need smart and sensible policies to guide us there. Through Vax Pass we can slowly yet surely advance towards that goal.',
    vision3Para2Start: 'Join us by',
    vision3Para2Link: 'getting your Vax Pass now',
    vision3Para2End: ', which will soon to be supported across the globe.',

    verification1Title: 'Digital Verification',
    verification1Para1Start: 'Digital verification generates a',
    verification1Para1Bold: 'Green Pass',
    verification1Para1End: 'that, by its very nature, cannot be falsified. By verifying that the record exists within the records of medical institutions the validity of the pass is guaranteed.',
    verification1Para2Start: 'Currently this method of verification',
    verification1Para2Bold: 'supports over 600 medical institutions',
    verification1Para2End: 'across the United States and over 100 million patients. See if a medical institution is currently supported here.',
    verification1Para3Start: 'Contact',
    verification1Para3End: 'for information on expanding support to other medical institutions.',

    verification2Title: 'Image Verification',
    verification2Para1Start: 'Image verification generates a',
    verification2Para1Bold: 'Yellow Pass',
    verification2Para1End: ', allowing businesses to provide an additional layer of safety for their customers.',
    verification2Para2Start: 'This works through',
    verification2Para2Bold: 'state of the art machine learning techniques',
    verification2Para2End: 'that automatically verify medical record images.',
    verification2Para3: '10% of the records are manually screened for fraud with consequences for those who falsify records.',

    privacyTitle: 'Privacy & Safety',
    privacy1Title: 'Vax Pass',
    privacy1Content: 'is designed to enhance the user experience while minimizing the risk of Covid transmission:',
    privacy2Title: 'CDC Guidelines:',
    privacy2Content: 'Successfully follow CDC guidelines by knowing that everyone present is verified',
    privacy3Title: 'Cloud technologies:',
    privacy3Content: 'Using the latest Amazon cloud technologies Vax Pass works on a quick and secure system',
    privacy4Title: 'Global Support:',
    privacy4Content: 'Vax Pass will soon be accepted as Covid-19 vaccination proof across the globe',
    privacy5Title: 'Digitally Signed:',
    privacy5Content: 'Multiple digital footprints are reviewed to guarantee pass validity',

    newsTitle: 'News',
    news1Heading: 'EU to propose a ‘digital pass’ for COVID-19 vaccination/test status to...',
    news1Body: 'The European Commission has said it will present a legislative plan later this month for what it’s calling a “digital green pass” — aka a digital certificate — which it says will be aimed at facilitating cross-border travel in the age of coronavirus.',
    news2Heading: 'Broadway theaters to reopen as part of NY PopsUp Festival',
    news2Body: 'Following the announcement of an arts pop up festival taking place across New York City, Governor Cuomo has announced that live indoor performances can resume from April 2. A number of “flex venues” will be opening for ...',
    news3Heading: 'Fully vaccinated Texans will soon pass confirmed cases statewide',
    news3Body1: 'A year after the state\'s first confirmed case of COVID-19, the number of fully vaccinated Texans will soon surpass the total number of reported coronavirus cases statewide.',
    news3Body2: 'More than 7% of Texans, or 2,177,635 people, are fully...',
    news4Heading: 'Israel reopens to fully vaccinated ‘Green Pass’ holders — nearly half of...',
    news4Body: 'Prime Minister Benjamin Netanyahu of Israel enjoyed a cappuccino and cake on the terrace of a Jerusalem cafe on Sunday morning to mark the broadest reopening of the country’s economy since the first coronavirus lockdown began a year ago.',
    news5Heading: 'British Airways plans app-based travel pass',
    news5Body: 'Under the plans, people who have had both jabs will be able to register their status on BA\'s smartphone app. The airline\'s chief executive, Sean Doyle, hailed the UK\'s "great progress" in tackling the pandemic.',

    getInTouch: 'Get In Touch',

    sendMessageTitle: 'Send a Message',
    sendMessageName: 'Your name',
    sendMessageEmail: 'Email',
    sendMessagePhone: 'Your phone number',
    sendMessageContent: 'Write your message...',
    sendMessageButton: 'SEND MESSAGE',
    sendMessageSentButton: 'SENT',

    headerHome: 'Home',
    headerHowItWorks: 'How It Works',
    headerStatus: 'Status',
    headerVision: 'Our Vision',
    headerPrivacy: 'Privacy & Safety',
    headerNews: 'News',
    headerContact: 'Contact',

    footerHowItWorks: 'How It Works',
    footerStatus: 'Status',
    footerVision: 'Our Vision',
    footerPrivacy: 'Privacy & Safety',
    footerNews: 'News',
    footerContact: 'Contact',

    'error.occured': 'Sorry - An Error Occured',
    'error.homeButton': 'Go Home',


    'verification.errorStart': 'There was an error verifying your',
    'verification.errorBold': 'COVID-19',
    'verification.errorEnd': 'record',

    'verification.tryImage': 'TRY IMAGE VERIFICATION',


    'imageVerification.header': 'Image Verification Screen',
    'imageVerification.firstName': 'First Name',
    'imageVerification.lastName': 'Last Name',
    'imageVerification.email': 'Email Address',
    'imageVerification.dob': 'Date of Birth (MM/DD/YYYY)',
    'imageVerification.selectImage': 'Select Image',
    'imageVerification.imageSelected': 'Image selected',
    'imageVerification.imageDescription': 'PCR test result or vaccination card',
    'imageVerification.imageInvalid': 'Please upload a valid image',
    'imageVerification.submit': 'SUBMIT',


    'instantVerification.description': 'Vax Pass allows patients to temporarily share parts of their health summary, including immunizations',
    'instantVerification.formHeader': 'ENTER YOUR INFORMATION',
    'instantVerification.firstName': 'First Name',
    'instantVerification.lastName': 'Last Name',
    'instantVerification.dob': 'Date of Birth (MM/DD/YYYY)',
    'instantVerification.email': 'Email',
    'instantVerification.username': 'Username',
    'instantVerification.password': 'Password',


    'introScreen.description1Start': 'Businesses use',
    'introScreen.description1Heading': 'VAX PASS',
    'introScreen.description1End': 'to verify you\'ve been vaccinated against or tested negative for Covid-19',

    'introScreen.description2Head': 'SECURE',
    'introScreen.description2Body': 'Transfer of your data is encrypted and secure.',

    'introScreen.description3Head': 'PRIVATE',
    'introScreen.description3Body': 'After the verification of your data, access to your medical record is disabled.',

    'introScreen.tosStart': 'By tapping \'Next\' you agree to our',
    'introScreen.tosLink1': 'Terms of Service',
    'introScreen.tosMiddle': 'and',
    'introScreen.tosLink2': 'Privacy Policy',

    'introScreen.next': 'NEXT',


    'loading.averageWaitTime': 'Average Wait Time',
    'loading.seconds': 'seconds',
    'loading.uploadingTitle': 'Uploading...',
    'loading.verifyingTitle': 'Verifying...',
    'loading.uploadingBody': 'Uploading your image for verification....',
    'loading.verifyingBody': 'Verifying vaccination record...',


    'pdfPass.text1': 'Thanks for choosing us.',
    'pdfPass.text2': 'Here\'s your Vax Pass for entry into Vax Pass zones. Your pass has been approved and confirmed. For any questions please send a message to contact@vax-pass.org',
    'pdfPass.text3': 'Visit www.vax-pass.org to see how Vax Pass works.',
    'pdfPass.text4': 'To add this pass to a wallet, open the email we sent you on an Apple or Android device.',
    'pdfPass.validityExpression': 'DATE OF TEST',
    'pdfPass.invalid': 'DATE OF TEST',
    'pdfPass.validAfter': 'VALID AFTER',
    'pdfPass.validUntil': 'VALID UNTIL',
    'pdfPass.name': 'NAME',
    'pdfPass.dob': 'DATE OF BIRTH',


    'selectProvider.title': 'Select Medical Provider',
    'selectProvider.searchText': 'Search Provider (i.e. Cone Health)',
    'selectProvider.cvs': 'CVS',
    'selectProvider.myChart': 'MyChart',


    'selectVerification.title': 'Select the type of verification',

    'selectVerification.digitalTitle1': 'Digital',
    'selectVerification.digitalTitle2': 'Verification',
    'selectVerification.digitalBody1': 'through your vaccine provider',
    'selectVerification.digitalBody2': '(Vaccine Only)',

    'selectVerification.imageTitle1': 'Image',
    'selectVerification.imageTitle2': 'Verification',
    'selectVerification.imageBody1': 'through vaccination or',
    'selectVerification.imageBody2': 'PCR test result image',


    'submission.success': 'Congratulations your record was successfully verified!',
    'submission.continue': 'CONTINUE',


    'verifiedRecord.invalidEmail': 'Please insert a valid email',
    'verifiedRecord.title': 'Save your VaxPass',
    'verifiedRecord.email': 'Email',
    'verifiedRecord.send': 'SEND TO EMAIL',
    'verifiedRecord.sent': 'EMAIL SENT',
    'verifiedRecord.saveApple': 'Save to Apple Wallet',
    'verifiedRecord.savePhone': 'SAVE TO PHONE',


    'registration.notValidUntil': 'Note: your pass will not be valid until',
    'registration.validTill': 'Note: your pass is only valid until the end of',
    'registration.notVerified': 'Your vaccine was not verified. Please try image verification instead.',
    'registration.passNotValid': 'Your negative PCR result was confirmed, however your pass is not valid. To get a valid pass please submit a negative result from the past 72 hours.',
    'registration.errorUploading': 'There was an error uploading your data. Please try again by reloading this page.',
    'registration.errorVerifying': 'Sorry, there was an error verifying your image',
    'registration.notFound': 'We could not find the page you are looking for',

    'successfulSubmissionText': 'Congratulations your record was submitted successfully! Please wait up to 24 hours for your record to be verified. We will send you an email when your verication is complete.'
};

export default en;
