const ru = {
'heroTitle': 'Пропуск Вакцинирования',
'heroHeadline': 'Быстро и безопасно вернись к жизни и путешествуй с Vax Pass. Получи свой пропуск',
'heroHeadlineLink': 'здесь',
'heroComingToAppstore': 'Приложение для сканирования скоро будет доступно в App Store',
'howItWorksTitle': 'Как это работает',
'howItWorks1Line1': 'Лабораторный тест / вакцинация',
'howItWorks1Line2': 'до проверки',
'howItWorks2Line1': 'Автоматическая проверка',
'howItWorks2Line2': 'результаты лабораторных исследований / вакцинация',
'howItWorks3Line1': 'Vax Pass сохраняется в',
'howItWorks3Line2': 'Кошелек Apple',
'howItWorks3Line3': 'Google Pay или',
'howItWorks3Line4': 'как PDF',
'howItWorks4Line1': 'Проходит сканирование предприятий',
'howItWorks4Line2': 'для проверки личности клиентов и состояния их здоровья',
'howItWorksBottom': 'Vax Pass следует рекомендациям CDC, чтобы минимизировать риск передачи Covid-19. Посетите веб-сайт CDC, посвященный Covid-19, чтобы узнать о последних обновлениях.',
'visionTitle': 'Наше видение',
'vision1Para1': 'Сейчас мы находимся на этапе, когда наши знания о Covid-19 могут помочь нам на пути к нормальной жизни. Принимая разумную меры, мы можем открыть экономику заново, снизив риск до приемлемого уровня. Если все будет сделано безопасно и ответственно, целые сообщества вскоре достигнут коллективного иммунитета.',
'vision1Para2': 'Мы надеемся, что наш продукт позволит предприятиям любого типа быстро и безопасно открыться заново.',
'vision2Para1': 'После этого предприятия, использующие Vax Pass, могут безопасно открыться, что позволит возобновить работу крупных секторов экономики. Среди ослабленных предприятий, которым может помочь Vax Pass, являются стадионы, ночные клубы, бары и авиакомпании.',
'vision2Para2': 'Поскольку процесс вакцинации ускоряется во всем мире, Vax Pass позволит людям безопасно и надежно участвовать в своей экономике и вернуться к новой «нормальной жизни».',
'vision3Para1': 'Если мы когда-либо надеемся остановить распространение Covid-19 и оставить его в прошлом, тогда нам нужна последовательная и разумная политика, которая направит нас в нужном направлении. С помощью Vax Pass мы можем медленно, но уверенно продвигаться к этой цели.',
'vision3Para2Start': 'Присоединяйтесь к нам',
'vision3Para2Link': 'получить Vax Pass сейчас',
'vision3Para2End': ', который скоро будет поддерживаться по всему миру.',
'verification1Title': 'Цифровая проверка',
'verification1Para1Start': 'Цифровая верификация генерирует',
'verification1Para1Bold': 'Зеленый пропуск',
'verification1Para1End': 'который не может быть сфальсифицирован. Подтверждение наличия записи в записях медицинских учреждений гарантирует действительность пропуска.',
'verification1Para2Start': 'В данный момент этот метод проверки',
'verification1Para2Bold': 'поддерживает более 600 медицинских учреждений',
'verification1Para2End': 'в Соединенных Штатах и более 100 миллионов пациентов. Здесь можно посмотреть, поддерживается ли в настоящее время медицинское учреждение.',
'verification1Para3Start': 'Свяжитесь',
'verification1Para3End': 'для получения информации о расширении поддержки других медицинских учреждений.',
'verification2Title': 'Проверка изображения',
'verification2Para1Start': 'Проверка изображения генерирует',
'verification2Para1Bold': 'Желтый пропуск',
'verification2Para1End': ', позволяя предприятиям обеспечить дополнительный уровень безопасности для своих клиентов.',
'verification2Para2Start': 'Это работает через',
'verification2Para2Bold': 'современные методы машинного обучения',
'verification2Para2End': 'которые автоматически проверяют изображения медицинских карт.',
'verification2Para3': '10% записей проверяются вручную на предмет мошенничества с последствиями для тех, кто фальсифицирует записи.',
'privacyTitle': 'Конфиденциальность и безопасность',
'privacy1Title': 'Vax Pass',
'privacy1Content': 'разработан для улучшения взаимодействия с пользователем при минимальном риске передачи Covid-19:',
'privacy2Title': 'Рекомендации CDC:',
'privacy2Content': 'Успешно следуйте рекомендациям CDC, зная, что все присутствующие проверены',
'privacy3Title': 'Облачные технологии:',
'privacy3Content': 'Используя новейшие облачные технологии Amazon, Vax Pass работает в быстрой и безопасной системе.',
'privacy4Title': 'Глобальная поддержка:',
'privacy4Content': 'Vax Pass скоро будет признан доказательством вакцинации против Covid-19 во всем мире',
'privacy5Title': 'С цифровой подписью:',
'privacy5Content': 'Множественные цифровые следы проверяются, чтобы гарантировать действительность пропуска.',
'newsTitle': 'Новости',
'news1Heading': 'ЕС предлагает «цифровой пропуск» для проверки статуса вакцинации / тестирования на COVID-19 для ...',
'news1Body': 'Европейская комиссия заявила, что представит законодательный план в конце этого месяца для того, что она называет «цифровым зеленым пропуском» - или цифровым сертификатом, - который, по ее словам, будет направлен на облегчение международных поездок в эпоху коронавируса.',
'news2Heading': 'Бродвейские театры вновь откроются в рамках фестиваля NY PopsUp',
'news2Body': 'После объявления о проведении фестиваля искусств в Нью-Йорке, губернатор Куомо объявил, что живые выступления в закрытых помещениях могут возобновиться со 2 апреля. Ряд «гибких площадок» откроется в течение ...',
'news3Heading': 'У полностью вакцинированных жителей Техаса скоро будут подтвержденные случаи заболевания по всему штату',
'news3Body1': 'Через год после первого подтвержденного случая заболевания COVID-19 в штате количество полностью вакцинированных жителей Техаса вскоре превысит общее количество зарегистрированных случаев коронавируса по всему штату.',
'news3Body2': 'Более 7% техасцев, или 2 177 635 человек, полностью ...',
'news4Heading': 'В Израиле вновь открывается доступ к полностью вакцинированным обладателям «Зеленого пропуска» - почти половине ...',
'news4Body': 'Премьер-министр Израиля Вениамин Нетаньяху наслаждался капучино и пирожным на террасе иерусалимского кафе в воскресенье утром в ознаменование самого широкого возобновления экономики страны с момента первой блокировки коронавируса, начавшейся год назад.',
'news5Heading': 'British Airways планирует проездной на основе приложения',
'news5Body': 'Согласно планам, люди, получившие оба укола, смогут зарегистрировать свой статус в приложении BA для смартфонов. Генеральный директор авиакомпании Шон Дойл приветствовал «большой прогресс» Великобритании в борьбе с пандемией.',
'getInTouch': 'Контактные Данные',
'sendMessageTitle': 'Отправить сообщение',
'sendMessageName': 'Ваше имя',
'sendMessageEmail': 'Электронное письмо',
'sendMessagePhone': 'Твой номер телефона',
'sendMessageContent': 'Напишите свое сообщение ...',
'sendMessageButton': 'ОТПРАВИТЬ СООБЩЕНИЕ',
'sendMessageSentButton': 'ОТПРАВЛЕНО',
'headerHome': 'Домой',
'headerHowItWorks': 'Как это работает',
'headerStatus': 'Текущий статус',
'headerVision': 'Наше видение',
'headerPrivacy': 'Конфиденциальность и безопасность',
'headerNews': 'Новости',
'headerContact': 'Контакт',
'footerHowItWorks': 'Текущий статус',
'footerStatus': 'Наше видение',
'footerVision': 'Конфиденциальность и безопасность',
'footerPrivacy': 'Новости',
'footerNews': 'Контакт',
'footerContact': 'Извините - произошла ошибка',
'error.occured': 'Вернуться на главную',
'error.homeButton': 'При проверке вашего',
'verification.errorStart': 'COVID-19',
'verification.errorBold': 'записывать',
'verification.errorEnd': 'ПОПРОБУЙТЕ ПРОВЕРКУ ИЗОБРАЖЕНИЯ',
'verification.tryImage': 'Экран проверки изображения',
'imageVerification.header': 'Имя',
'imageVerification.firstName': 'Фамилия',
'imageVerification.lastName': 'Адрес электронной почты',
'imageVerification.email': 'Дата рождения (мм / дд / гггг)',
'imageVerification.dob': 'Выбрать изображение',
'imageVerification.selectImage': 'Изображение выбрано',
'imageVerification.imageSelected': 'Результат ПЦР-теста или карта вакцинации',
'imageVerification.imageDescription': 'Пожалуйста, загрузите действительное изображение',
'imageVerification.imageInvalid': 'РАЗМЕСТИТЬ',
'imageVerification.submit': 'Vax Pass позволяет пациентам временно делиться частями своего состояния здоровья, включая иммунизацию.',
'instantVerification.description': 'ВВЕДИТЕ ВАШУ ИНФОРМАЦИЮ',
'instantVerification.formHeader': 'Имя',
'instantVerification.firstName': 'Фамилия',
'instantVerification.lastName': 'Дата рождения (мм / дд / гггг)',
'instantVerification.dob': 'Электронное письмо',
'instantVerification.email': 'Имя пользователя',
'instantVerification.username': 'Пароль',
'instantVerification.password': 'Предприятия используют',
'introScreen.description1Start': 'VAX PASS',
'introScreen.description1Heading': 'чтобы убедиться, что вы были вакцинированы или дали отрицательный результат на Covid-19',
'introScreen.description1End': 'БЕЗОПАСНЫЙ',
'introScreen.description2Head': 'Передача ваших данных зашифрована и безопасна.',
'introScreen.description2Body': 'ЧАСТНЫЙ',
'introScreen.description3Head': 'После проверки ваших данных доступ к вашей медицинской карте будет отключен.',
'introScreen.description3Body': 'Нажимая "Далее", Вы соглашаетесь с нашими',
'introScreen.tosStart': 'условия обслуживания',
'introScreen.tosLink1': 'и',
'introScreen.tosMiddle': 'Политика конфиденциальности',
'introScreen.tosLink2': 'СЛЕДУЮЩИЙ',
'introScreen.next': 'Среднее время ожидания',
'loading.averageWaitTime': 'секунды',
'loading.seconds': 'Загрузка ...',
'loading.uploadingTitle': 'Проверка ...',
'loading.verifyingTitle': 'Загрузка изображения для проверки ....',
'loading.uploadingBody': 'Проверка записи о вакцинации ...',
'loading.verifyingBody': 'Спасибо, что выбрали нас.',
'pdfPass.text1': 'Вот Ваш Пропуск Вакцинирования для входа в зоны Vax Pass. Ваш пропуск был одобрен и подтвержден. По любым вопросам отправляйте сообщение на contact@vax-pass.org',
'pdfPass.text2': 'Посетите www.vax-pass.org, чтобы узнать, как работает Vax Pass.',
'pdfPass.text3': 'Чтобы добавить этот пропуск в кошелек, откройте письмо, которое мы отправили вам, на устройстве Apple или Android.',
'pdfPass.text4': 'ДАТА ПРОВЕРКИ',
'pdfPass.validityExpression': 'ДАТА ПРОВЕРКИ',
'pdfPass.invalid': 'ДЕЙСТВИТЕЛЬНО ПОСЛЕ',
'pdfPass.validAfter': 'ГОДЕН ДО',
'pdfPass.validUntil': 'ИМЯ',
'pdfPass.name': 'ДАТА РОЖДЕНИЯ',
'pdfPass.dob': 'Выберите поставщика медицинских услуг',
'selectProvider.title': 'Поставщик поиска (например, Cone Health)',
'selectProvider.searchText': 'CVS',
'selectProvider.cvs': 'MyChart',
'selectProvider.myChart': 'Выберите тип проверки',
'selectVerification.title': 'Цифровой',
'selectVerification.digitalTitle1': 'Проверка',
'selectVerification.digitalTitle2': 'через вашего поставщика вакцины',
'selectVerification.digitalBody1': '(Только вакцина)',
'selectVerification.digitalBody2': 'Изображение',
'selectVerification.imageTitle1': 'Проверка',
'selectVerification.imageTitle2': 'через вакцинацию или',
'selectVerification.imageBody1': 'Изображение результата теста ПЦР',
'selectVerification.imageBody2': 'Поздравляем, ваша запись была успешно подтверждена!',
'submission.success': 'ПРОДОЛЖИТЬ',
'submission.continue': 'Пожалуйста, введите действующий адрес электронной почты',
'verifiedRecord.invalidEmail': 'Сохраните свой VaxPass',
'verifiedRecord.title': 'Электронное письмо',
'verifiedRecord.email': 'ОТПРАВИТЬ НА ЭЛЕКТРОННУЮ ПОЧТУ',
'verifiedRecord.send': 'ПИСЬМО ОТПРАВЛЕНО',
'verifiedRecord.sent': 'Сохранить в Apple Wallet',
'verifiedRecord.saveApple': 'СОХРАНИТЬ НА ТЕЛЕФОН',
'verifiedRecord.savePhone': 'Примечание: ваш пропуск действителен только до конца',
'registration.notValidUntil': 'Ваша вакцина не была проверена. Попробуйте вместо этого проверить изображение.',
'registration.validTill': 'Ваш отрицательный результат ПЦР подтвержден, однако ваш пропуск недействителен. Чтобы получить действительный пропуск, отправьте отрицательный результат за последние 72 часа.',
'registration.notVerified': 'При загрузке ваших данных произошла ошибка. Пожалуйста, попробуйте еще раз, перезагрузив эту страницу.',
'registration.passNotValid': 'Извините, при проверке вашего изображения произошла ошибка',
'registration.errorUploading': 'Мы не смогли найти страницу, которую вы ищете',
'successfulSubmissionText': 'Поздравляем, ваша запись была успешно загружена! Пожалуйста, дайте до 24 часов для проверки вашей записи. Мы отправим электронное письмо, когда проверка будет завершена.'
};
 export default ru;
