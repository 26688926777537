import React from "react";
// reactstrap components
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import "./stylesheets/IntroScreen.scss";
import errorImage from "../assets/img/vaxpass/errorImage.png";
import { BlueButton } from "./Buttons";
import { I18n } from 'aws-amplify';

class ErrorScreen extends React.Component {
  render() {
    const { errorMessage } = this.props;
    return (
      <VaxContainer>
        <VaxHeader canGoBack={false} />
        <div className="mb-5 vax-intro-screen">
          <div className="d-flex">
            <img
              src={errorImage}
              alt=""
              className="mb-3 mx-auto intro-screen-img"
            ></img>
          </div>
          <h5 className="mt-3 vax-pass-img-caption">
            {I18n.get('error.occured')}
          </h5>
          <hr className="divider"></hr>
          <div className="mx-5 mb-5">
            <h6 className="text-center text-light">{errorMessage}</h6>
          </div>
          <BlueButton
            onClick={() => this.props.switchScreen("IntroductionScreen")}
          >
            {I18n.get('error.homeButton')}
          </BlueButton>
        </div>
      </VaxContainer>
    );
  }
}

export default ErrorScreen;
