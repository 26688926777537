import React from "react";
// reactstrap components
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import "./stylesheets/SelectVerificationScreen.scss";
import instantVerificationLock from "../assets/img/vaxpass/instantVerificationLock.svg";
import sameDayVerificationIcon from "../assets/img/vaxpass/sameDayVerificationIcon.svg";
import { I18n } from 'aws-amplify';

class WelcomeScreens extends React.Component {
  render() {
    return (
      <VaxContainer>
        <VaxHeader
          canGoBack
          goBack={() => this.props.switchScreen("IntroductionScreen")}
        />
        <div className="mb-5">
          <h5 className="mt-3 mb-5"> {I18n.get('selectVerification.title')} </h5>
          <div className="d-flex my-3">
            <button
              className="welcome-screen-large-button"
              onClick={() => this.props.switchScreen("SelectProvider")}
            >
              <img src={instantVerificationLock} alt="" className="mb-3"></img>
              <h6
                className="title"
                style={{ textTransform: "uppercase", lineHeight: "100%" }}
              >
                {I18n.get('selectVerification.digitalTitle1')} <br /> {I18n.get('selectVerification.digitalTitle2')}

              </h6>
              <p className="subtitle" style={{ lineHeight: "100%" }}>
                {I18n.get('selectVerification.digitalBody1')}
                <br /> {I18n.get('selectVerification.digitalBody2')}
              </p>
            </button>
          </div>
          <div className="d-flex my-3">
            <button
              className="welcome-screen-large-button"
              onClick={() => this.props.switchScreen("ImageVerification")}
            >
              <img src={sameDayVerificationIcon} alt="" className="mb-3"></img>
              <h6
                className="title"
                style={{ textTransform: "uppercase", lineHeight: "100%" }}
              >
                {I18n.get('selectVerification.imageTitle1')} <br /> {I18n.get('selectVerification.imageTitle2')}
              </h6>
              <p className="subtitle" style={{ lineHeight: "100%" }}>
              {I18n.get('selectVerification.imageBody1')} <br /> {I18n.get('selectVerification.imageBody2')}
              </p>
            </button>
          </div>
        </div>
      </VaxContainer>
    );
  }
}

export default WelcomeScreens;
